@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    margin: 0;
    height: 100vh;
    background: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.table-container {
    flex: 1;
    padding: 20px;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    z-index: 0;
}

.confirm-dialog-yes {
    margin-left: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
}

.confirm-dialog-no {
    margin-left: auto;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background-color: #dc3545;
    color: #fff;
}

.select-dates {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.select-dates .calendar {
    width: 250px;
    height: 40px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
}

.select-dates .p-inputtext {
    padding: 0.75rem 0.75rem;
}

.select-dates .p-button {
    background: #007bff;
    border: 1px solid #007bff;
    color: #fff;
}

.select-time input {
    width: 70px;
    height: 40px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
}

.select-time .p-inputtext {
    padding: 0.75rem 0.75rem;
}

.remove-border-dates {
    border: none;
}

.main-table .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e5e7eb;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #374151;
    background: #f9fafb;
    transition: box-shadow 0.2s;
}

.main-table .p-datatable-tbody>tr {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e5e7eb;
    border-width: 0 0 1px 0;
    color: #374151;
    transition: box-shadow 0.2s;
}

.main-table .p-inputtext {
    padding: 0.75rem 0.75rem;
    border-radius: 6px;
    border: 1px solid #d1d5db;
}

.main-table .p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: #f8f8fa;
}

.main-table .p-datatable.p-datatable-striped .p-datatable-tbody>tr.selected-row {
    background-color: #d3d3d3;
}

.ticket-type-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0.75rem;
}

.ticket-type-container .left {
    width: 50%;
    text-align: left;
}

.ticket-type-container .right {
    width: 50%;
    text-align: right;
}

.auto-margin-left {
    margin-left: auto;
    padding: 0 1rem;
}

.p-rowgroup-header-name {
    padding-left: 10px;
    width: 100%;
}

.event-expandable-row {
    display: flex;
    width: 100%;
}

.first-div {
    width: 5%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    position: relative;
    /* Needed for absolute positioning */
}

.second-div {
    width: 85%;
}

.third-div {
    display: flex;
    width: 10%;
    text-align: right;
}

.event-expandable-row img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    max-width: 50px;
}

.event-image {
    border-radius: 50%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.event-actions {
    display: flex;
    margin-left: 0px;
}

.p-rowgroup-header td {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.end-of-expandable-row {
    border-bottom: 1px solid #ccc;
}

.main-table-level-1 .p-rowgroup-header td {
    display: table-cell;
}


.main-table-level-1 .p-link {
    height: 10px;
}

.active-tag {
    width: 60px;
}

.main-table-level-1 .p-rowgroup-header {
    background: #ecfeff;
    color: #0e7490;
}

.p-row-toggler {
    height: 70px;
}

.main-table-level-0 .p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: #f8f8fa;
}

.pick-list-container .p-picklist {
    display: flex;
    width: 100%;
}

.pick-list-container .p-picklist-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pick-list-container .p-picklist .p-picklist-header {
    background: #f9fafb;
    color: #374151;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    font-weight: 700;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #0e7490;
    background: #ecfeff;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #0e7490;
    background: #ecfeff;
}

.pick-list-container .p-picklist-item {
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.pick-list-container .p-button {
    color: #ffffff;
    background: #06b6d4;
    border: 1px solid #06b6d4;
    /* padding: 0.75rem 1.25rem; */
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

.pick-list-container .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
}

.pick-list-container .p-picklist-buttons .p-button,
.pick-list-container .p-orderlist-controls .p-button {
    transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.pick-list-container .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
}

.pick-list-container .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
}

.pick-list-container .p-picklist .p-picklist-header {
    background: #f9fafb;
    color: #374151;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    font-weight: 700;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.pick-list-container .p-picklist .p-picklist-filter-container {
    padding: 1.25rem;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-bottom: 0 none;
}

.pick-list-container .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.75rem;
}

.pick-list-container .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.75rem;
    color: #6b7280;
}

.pick-list-container .p-picklist .p-picklist-list {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #4b5563;
    padding: 0.75rem 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    outline: 0 none;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.75rem 1.25rem;
    margin: 0;
    border: 0 none;
    color: #4b5563;
    background: transparent;
    transition: transform 0.2s, box-shadow 0.2s;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #f3f4f6;
    color: #4b5563;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #4b5563;
    background: #e5e7eb;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #0e7490;
    background: #ecfeff;
}

.pick-list-container .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: rgba(6, 182, 212);
    color: #fff;
}

.pick-list-container .p-picklist-source-controls {
    display: none;
}

.pick-list-container .p-picklist-target-controls {
    display: none;
}

.max-height-side-panel {
    max-height: 40px;
}

.max-width-side-panel {
    max-width: 248px;
}

.top-bar-filtering .p-dropdown-label {
    margin-top: -5px;
}

.cash-out {
    height: 40px;
    margin-top: 32px;
    padding: 10px 20px;
}

.layout {
    display: flex;
}

.main-content {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
}

.label-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0px 16px;
}

.ticket-sales-repeat .image-container img {
    max-width: 250px;
    max-height: 230px;

}

.ticket-sales-repeat .image-container {
    min-width: 300px;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.ticket-sales-repeat {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.ticket-sales-repeat .text-label {
    width: 90px;
    height: 40px;
    font-size: 16px;
    padding: 0px;
    font-weight: bold;
}

.arrow-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-icon {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
    /* Arrow pointing to the right by default */
}

.arrow-icon.rotated {
    transform: rotate(180deg);
    /* Arrow pointing down when rotated */
}

.arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50px;
    /* Adjust width as needed */
}

.bar-graph {
    padding-top: 20px;
    padding-bottom: 10px;
}


.welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
    /* White background */
}

.welcome-box {
    background-color: #f9f9f9;
    /* Light grey for the box background */
    border: 2px solid #D72B63;
    /* Border color matching the original background */
    border-radius: 10px;
    /* Rounded corners */
    padding: 40px;
    /* Padding inside the box */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
}

.welcome-message {
    font-size: 36px;
    /* Larger font size for welcome message */
    color: #D72B63;
    /* Color matching the border */
    margin-bottom: 20px;
}

.greeting-message {
    font-size: 24px;
    /* Font size for greeting message */
    color: #333;
    /* Dark color for text */
}

.table-header .p-button {
    text-align: right;
    padding: 10px 20px;
    border: none;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
}

.selected-row {
    background-color: #d3d3d3;
    /* Change this color to your preferred highlight color */
}

.ticket-management-lance {
    background-color: #007bff;
    color: white;
}

.p-paginator .p-highlight {
    font-weight: bold;
    color: #000000;
}

.p-toast .p-toast-message {
    max-width: 400px;
    word-wrap: break-word;
    white-space: normal;
}

.p-toast .p-toast-message-detail {
    max-height: 150px;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .welcome-container {
        width: 90%;
    }
}